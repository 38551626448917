<template>
  <v-row class="rowDialog" v-if="barCodeData">
    <v-dialog v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown" :max-width="info ? 700 : 430" class="dialog">
      <v-card class="contentCard">
        <v-card-title class="headline pb-0">
          <v-col cols="10" class="modal-title" v-if="!info">
            Novo Pagamento
          </v-col>
          <v-col cols="10" class="modal-title" v-else>
            Informações do Boleto
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

        <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>
        <v-card-text class="modal-form pr-0 pl-0" v-if="info">
            <v-container>
              <v-form
              ref="form"
              @submit.prevent="submit"
              >
              <v-row justify="center">
                <v-col md="6" align="center">
                  <h5 class="titles">Dados do Beneficiário</h5>
                  <h5 class="values">{{barCodeData.beneficiary.name}}</h5>
                </v-col>
                <v-col md="6" align="center">
                  <h5 class="titles">Dados do Pagador</h5>
                  <h5 class="values">{{drawee.name}}</h5>
                </v-col>
              </v-row>
              <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>
              <v-row justify="center">
                <v-col md="12" align="center" >
                  <h5 class="titles">Dados do Pagamento</h5>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col md="4" align="center">
                  <h5 class="subTitles">Valor do Boleto</h5>
                  <h5 class="values">
                    {{services.formatCurrency(barCodeData.originalAmount)}}
                  </h5>
                </v-col>
                <v-col md="4" align="center">
                  <h5 class="subTitles">Vencimento</h5>
                  <h5 class="values">
                    {{duoDate}}
                  </h5>
                </v-col>
              </v-row>
              <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>
              <v-row md="12" justify="center" class="mt-12">
                <v-col md="7" cols="12" align="center">
                  <h5 class="barcode">
                    {{services.billetsFormat(barCodeData.paymentDetails.barCode)}}
                  </h5>
                </v-col>
                <v-col md="12" cols="12" align="center">
                  <img class="barcode-img" src="@/assets/barcode.png" alt="">
                </v-col>
              </v-row>
              <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>
              <v-row md="12" justify="center" class="mt-5">
                <v-col md="12" class="mb-0 pb-0">
                  <h5 class="title-value">Valor a Pagar</h5>
                </v-col>
                <v-col md="5" cols="12" class="mt-0 pt-0 mb-0 pb-0">
                  <v-text-field
                    v-model.lazy="barCodeData.paymentDetails.totalAmount"
                    class="value-field"
                    v-money="money"
                    color="#8C8C8C"
                    maxlength="12"
                    readonly
                    validate-on-blur
                    single-line
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="10" class="mt-0 pt-0">
                  <v-row justify="center">
                  <v-col md="5" cols="12" class="mt-0 pt-0 pb-0">
                    <v-row justify="center">
                      <v-col md="6" class="pt-0 pb-0 mt-2">
                        <h5 class="total-value-title">
                            Saldo Total
                        </h5>
                      </v-col>
                      <v-col md="6" class="pt-0 pb-0">
                        <h5 class="total-value">
                            {{services.formatCurrency(balance)}}
                        </h5>
                      </v-col>
                    </v-row>
                  </v-col>
                  </v-row>
                </v-col>
                <v-col md="9">
                  <v-row justify="center">
                    <h5 class="values space">
                        Pagar em:
                    </h5>
                    <h5 class="values">
                        {{this.$moment().format('DD/MM/YYYY')}}
                    </h5>
                  </v-row>
                </v-col>
                <v-col md="8" cols="10">
                  <v-row justify="center">
                    <h5 class="values space mt-3">
                        Identificação do Comprovante
                    </h5>
                    <v-text-field
                      v-model="comment"
                      single-line
                      outlined
                      :rules="commentRules"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>
              <v-row justify="center" class="buttons-order mt-md-12 mt-0">
                <v-col md="6" cols="12" align="center">
                  <v-btn rounded color="#00C1C1" @click="closeModal" dark
                    outlined class="btnBack">
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col md="6" cols="12" align="center">
                  <v-btn rounded @click="submit" color="#00C1C1" dark
                    class="btnBack">
                    Confirmar
                  </v-btn>
                </v-col>
              </v-row>
              </v-form>
            </v-container>
        </v-card-text>
        <v-col v-if="getLoading" cols="12" align="center">
          <v-progress-circular
          :size="100"
          color="#D70472"
          indeterminate
          ></v-progress-circular>
          <h3 v-if="!textPassword" class="textInfoDisable mt-6">Efetuando o pagamento...</h3>
          <h3 v-else class="textInfoDisable mt-6">Enviando e-mail...</h3>
        </v-col>
        <v-card-text v-show="!getLoading" class="modal-form pr-0 pl-0"
          v-if="!info && !successPayment && !emailSended && !errorPayment">
          <v-row justify="center">
              <v-row v-if="error" justify="center">
                <v-col md="10" align="center">
                  <v-alert outlined color="red"> {{error}} </v-alert>
                </v-col>
              </v-row>
            <v-col md="7" cols="10" class="pt-5 pb-md-0 pb-12">
              <v-row justify="center">
                <span class="text-success">
                  Para completar o pagamento, insira a senha da sua conta digital:
                </span>
              </v-row>
              <v-row justify="center" class="mt-0">
                <v-col md="11" cols="10" class="mt-5 mt-md-0 pb-0 password">
                  <v-text-field class="password-field" maxlength="6"
                    type=password v-mask="'######'" placeholder="_ _ _ _ _ _"
                    single-line dense rounded outlined v-model="password" />
                </v-col>
                <v-col md="12" align="center">
                  <h5 class="forgetPassword" @click="forgetMyPassword"> Esqueci minha senha.</h5>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="11" md="12" class="pt-5">
              <v-row justify="center">
                <v-btn @click="confirmPayment" :disabled ="!hasPassword" rounded color="#00C1C1"
                  :dark ="hasPassword" :ligth="!hasPassword" class="btnBack mt-0 mb-0">
                  Confirmar Pagamento
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="11" md="12" class="pt-0">
              <v-row justify="center">
                <v-btn rounded color="#8C8C8C" outlined @click="closePassword" class="btnClose">
                  Cancelar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="modal-form pl-0 pr-0" v-if='errorPayment'>
          <v-row justify="center">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-5 mt-12" src="@/assets/erro.png" v-if='errorPayment' />
              </v-row>
            </v-col>
            <v-col cols="9" md="8" class="pb-10">
              <v-row justify="center" v-if='error'>
                <span class="text-erro">Erro no Pagamento</span>
                <span class="text-confirm">
                  {{error}}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="modal-form pl-0 pr-0" v-if='emailSended && !error && !info && !errorPayment && !successPayment'>
          <v-row justify="center">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-5 mt-12" src="@/assets/email.png"/>
              </v-row>
            </v-col>
            <v-col cols="9" md="6" class="pb-3">
              <v-row justify="center">
                <span class="successTitle">
                  Enviamos as instruções para o seu e-mail cadastrado!
                </span>
              </v-row>
            </v-col>
            <v-col cols="11" md="12" class="pt-0">
              <v-row justify="center">
                <v-btn rounded color="#8C8C8C" outlined @click="closeModal" class="btnClose">
                  Fechar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="modal-form pl-0 pr-0" v-if="successPayment">
          <v-container>
            <v-row justify="center">
              <v-col align="center" md="12" cols="12">
                <img src="@/assets/success.png" alt="">
              </v-col>
              <v-col md="12" cols="12">
                <h5 class="successTitle" align="center">
                  Pagamento Efetuado
                </h5>
              </v-col>
              <v-col md="12" class="mt-7" align="center">
                <h5 class="subTitleSuccess">Parabéns</h5>
                <p>
                  Seu pagamento no valor de <b>
                    {{barCodeData.paymentDetails.totalAmount}}
                  </b>
                  para <b>{{barCodeData.beneficiary.name}}</b> foi realizado com sucesso.
                </p>
              </v-col>
              <v-col md="12" cols="10" align="center">
                <v-btn @click="showReceipt" color="#00C1C1" dark width="210" rounded>
                  Ver Comprovante
                </v-btn>
              </v-col>
              <v-col md="12" cols="10"  align="center" >
                <v-btn color="#8C8C8C" width="210" @click="backHome" outlined rounded>
                  Voltar para a Home
                </v-btn>
              </v-col>
            </v-row>
            
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-receipt />
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import services from '../../../service';
import modalReceipt from './modalReceipt.vue';

export default {
  components: {
    modalReceipt,
  },
  props: {
    dialog: Boolean,
  },
  directives: {
    mask,
    money: VMoney,
  },
  data() {
    return {
      emailSended: false,
      errorPayment: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
      },
      comment: '',
      passwordModal: false,
      info: true,
      password: '',
      showPassword: false,
      loadingBtn: false,
      services,
      commentRules: [
        valueInput => valueInput.length < 40 || 'Insira até 40 caracteres',
      ],
      textPassword: false
    };
  },
  computed: {
    ...mapGetters({
      getRole: 'Auth/getRole',
      barCodeData: 'DigitalAccount/getBarCodeData',
      emailSuccess: 'DigitalAccount/getEmailPassword',
      error: 'DigitalAccount/getError',
      successPayment: 'DigitalAccount/getSuccessPayment',
      transactionId: 'DigitalAccount/getIdTransfer',
      account: 'DigitalAccount/getAccount',
      balance: 'DigitalAccount/getBalance',
      getLoading: 'Loading/getLoading'
    }),
    drawee() {
      if (this.barCodeData.drawee) {
        return this.barCodeData.drawee;
      }
      return {
        name: this.account.name,
      };
    },
    role() {
      return this.getRole;
    },
    hasPassword() {
      return this.password.length === 6;
    },
    duoDate() {
      return this.barCodeData.paymentDetails.dueDate 
      ? this.$moment(this.barCodeData.paymentDetails.dueDate).format('DD/MM/YYYY') 
      : this.$moment().format('DD/MM/YYYY');
    },
  },
  methods: {
    ...mapMutations({
      clearEmailSuccess: 'DigitalAccount/SET_SEND_EMAIL_PASSWORD',
      clearSuccessPayment: 'DigitalAccount/SET_SUCCESS_PAYMENT',
      clearBarcodeData: 'DigitalAccount/SET_BARCODE_DATA',
      clearError: 'DigitalAccount/CLEAR_ERROR'
    }),
    ...mapActions({
      forgetPassword: 'DigitalAccount/forgetPassword',
      validatePassword: 'DigitalAccount/generateTokenTransfer',
      pay: 'DigitalAccount/newPayment',
    }),
    async forgetMyPassword() {
      this.textPassword = true;
      await this.forgetPassword();
      this.textPassword = false;
      if (this.emailSuccess) {
        this.emailSended = true;
      }
    },
    closePassword() {
      this.clearError();
      this.password = '';
      this.errorPayment = false;
      this.info = true;
    },
    async closeModal() {
      this.info = true;
      this.errorPayment = false;
      this.emailSended = false;
      this.password = '';
      this.comment = '';
      this.newPaymentValue = null;
      this.clearBarcodeData(null);
      this.clearSuccessPayment(false);
      this.clearEmailSuccess(false);
      this.$emit('closeModal');
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.info = false;
      }
    },
    backHome() {
      const home = 'home';
      return this.$router.push({ path: `/customer/${home}` });
    },
    async confirmPayment() {
      if (this.password.length === 6) {
        await this.validatePassword(this.password);
        if (this.error) {
          this.password = '';
          this.errorPayment = false
          return;
        }
        const args = {
          totalAmount: parseFloat(this.services.cleanCurrency(
            this.barCodeData.paymentDetails.totalAmount)
          ),
          beneficiary: this.barCodeData.beneficiary.name,
          senderComment: this.comment,
          currency: 'BRL',
          withdrawType: this.barCodeData.paymentDetails.documentType,
          typeableLine: this.barCodeData.paymentDetails.barCode,
          dueDate: this.barCodeData.paymentDetails.dueDate || this.$moment().format('YYYY-MM-DD'),
        };
        if (!this.error) await this.pay(args);
        if (this.error) this.errorPayment = true;
        if (!this.error) this.$emit('reloadGrid');
      }
    },
    showReceipt() {
      this.$emit('seeReceiptPayment', this.transactionId);
      this.closeModal();
    },
  },
  destroyed() {
    this.clearBarcodeData(null);
    this.clearSuccessPayment(false);
    this.clearEmailSuccess(false);
    this.$emit('closeModal');
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/modalPayment.scss';
</style>
z