<template>
  <v-row class="rowDialog" v-if="receipt">
    <v-dialog
      persistent
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :max-width="700"
      class="dialog"
    >
      <v-card class="contentCard">
        <v-card-title class="headline pb-0">
          <v-col cols="11" md="10" class="modal-title" v-if="info">Comprovante de Pagamento</v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">mdi-window-close</v-icon>
          </v-col>
        </v-card-title>
        <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>
        <v-card-text id="contentReceipt" class="modal-form pl-0 pr-0 pr-md-8 pl-md-8" v-if="info">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" align="center" class="status pb-0">
                <img class="imgSuccess pb-2" src="@/assets/success.svg"/>
              </v-col>
              <v-col align="center" cols="12" class="text-success pt-0">Pagamento Efetuado</v-col>
              <v-col cols="12" align="center" class="data pr-0">
                  <span class="data-title">Dados do Pagador</span>
                  <span class="data-text mt-3 mb-3">{{receipt.source.name}}</span>
              </v-col>
               <v-divider></v-divider>
            </v-row>
              <v-col cols="12" align="center">
                <v-row justify='center'>
                  <v-col align="center">
                    <span class="title-document">Valor do pagamento</span>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col md="8" class="text-field pt-2 pb-0 mt-3">
                      <span class="value-recipe">{{services.formatCurrency(receipt.boleto.amount)}}</span>
                  </v-col>
                </v-row>
                <v-col class="data-title title-dataPayment pb-0">
                  <span>Dados do pagamento</span>
                </v-col>
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols='3' class="data pl-0 pr-0">
                        <span class="titles-dataPayment">Valor do boleto</span>
                        <span class="data-text">{{services.formatCurrency(receipt.boleto.amount)}}</span>
                    </v-col>
                    <v-col cols='3' class="data pl-0 pr-0">
                        <span class="titles-dataPayment">Vencimento</span>
                        <span class="data-text">{{$moment(receipt.boleto.dueDate).format('DD/MM/YYYY')}}</span>
                    </v-col>
                    <v-col cols="3"></v-col>
                  </v-row>
                  <div>
                    <v-col  v-if="receipt.boleto.barCode" class="data-title title-dataPayment pb-0">
                      <span>Código de Barras:</span>
                    </v-col>
                    <v-row justify="center">
                      <v-col v-if="receipt.boleto.barCode" md='10' cols="12" class="document-value">
                        <span>
                          {{services.billetsFormat(receipt.boleto.barCode)}}
                        </span>
                      </v-col>
                      <v-col cols="12" class="data-title title-dataPayment pb-0">
                        <span>Data e hora da efetivação do pagamento</span>
                      </v-col>
                      <v-col md="12" cols="12" class="pa-0">
                        <v-row justify="center">
                          <v-col cols="12" align="center" class="data-text">
                            {{`${$moment(receipt.transactionDate).format('DD/MM/YYY')} ${$moment(receipt.transactionDate).format('HH:mm:ss')}`}}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="data-title title-dataPayment pb-0">
                        <span>Código de autenticação</span>
                      </v-col>
                      <v-col md="12" cols="12" class="data-text pa-0">{{receipt.transactionId}}</v-col>
                    </v-row>
                  </div>
              </v-col>
            <v-divider></v-divider>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-container>
            <v-row v-if="!sendToEmail && !emailSuccess" justify="center">
              <v-col  md="5" cols="12" class="col-buttons">
                <v-btn class="button" color="#00C1C1"
                rounded outlined dark @click="seeSendEmail(true)">
                Enviar por e-mail
                </v-btn>
              </v-col>
              <v-col md="5" cols="12" class="col-buttons">
                <v-btn class="button" color="#00C1C1" rounded dark
                @click="print">Imprimir</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="sendToEmail && !emailSuccess" justify="center">
              <v-col cols="2" class="mt-3" v-if="$vuetify.breakpoint.mdAndUp">
                <span @click="seeSendEmail(false)" class="cancelEmail">Cancelar</span>
              </v-col>
              <v-form cols="8" class="pt-3" lazy-validation aling="center" ref="form">
                <v-text-field
                  type="email" 
                  class="sendEmail" 
                  placeholder="Insira o e-mail" 
                  v-model="email"
                  outlined
                  :rules="emailValidation">
                </v-text-field>
              </v-form>
              <v-col class="send" @click="validateEmail" cols="2" aling="center">
                <img src="@/assets/send.png" alt />
              </v-col>
            </v-row>
            <v-col v-if="$vuetify.breakpoint.smAndDown && sendToEmail && !emailSuccess" cols="12">
              <v-row justify="center">
                <span @click="seeSendEmail(false)" class="cancelEmail">Cancelar</span>
              </v-row>
            </v-col>
            <v-row v-if="emailSuccess" justify="center">
              <v-col cols="12" align="center">
                <span class="msgSuccess">
                  <b>Email enviado com sucesso!</b> Clique abaixo para enviar um novo email:
                </span>
              </v-col>
              <v-col md="5" cols="12" class="col-buttons">
                <v-btn class="button" color="#00C1C1" rounded dark
                @click="closeModal">Fechar</v-btn>
              </v-col>
              <v-col md="5" cols="12" class="col-buttons">
                <v-btn class="button" color="#00C1C1" rounded dark
                @click="seeSendEmail(true)">Enviar novo e-mail</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import services from '../../../service';

export default {
  props: {
    dialog: Boolean,
  },
  directives: {
    mask
  },
  data() {
    return {
      passwordModal: false,
      accomplished: false,
      info: true,
      password: '',
      showPassword: false,
      loadingBtn: false,
      sendToEmail: false,
      email: '',
      services,
      emailValidation: [
        valueInput => !!valueInput || 'Insira o e-mail',
        valueInput => (!valueInput || /.+@.+\..+/.test(valueInput)) || 'E-mail inválido',
      ],
    };
  },
  computed: {
    ...mapGetters({
      getRole: 'Auth/getRole',
      emailSuccess: 'DigitalAccount/getSendEmail',
      receipt: 'DigitalAccount/getReceiptTransfer',
      error: 'DigitalAccount/getError',
    }),

    role() {
      return this.getRole;
    },
  },
  methods: {
    ...mapActions({
      sendEmail: 'DigitalAccount/sendEmail',
    }),
    ...mapMutations({
      clearEmailSuccess: 'DigitalAccount/CLEAR_SEND_EMAIL',

    }),
    validateEmail() {
      if (this.$refs.form.validate()) {
        this.sendTransferToEmail();
     }
    },
    seeSendEmail(bol) {
      this.sendToEmail = bol;
      if (!bol) this.email = '';
      else this.clearEmailSuccess();
    },
    async sendTransferToEmail() {
      await this.sendEmail({
        id: this.receipt.transactionId,
        email: this.email,
      });
      this.seeSendEmail(false);
    },
    closeModal() {
      this.sendToEmail = false;
      this.clearEmailSuccess();
      this.$emit('closeModal');
    },
    print() {
      const prtHtml = document.getElementById('contentReceipt').innerHTML;

      const styles = [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ].reduce((acc, item) => acc + item.outerHTML, '');

      const printWindow = window.open(
        '',
        '',
        'left=0,top=0,height=900,toolbar=100,scrollbars=0,status=0',
      );

      printWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${styles}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);
      this.$emit('closeModal', false);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.stop();
      printWindow.close();
    },
    backHome() {
      const home = 'home';
      return this.$router.push({ path: `customer/${home}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/modalPayment.scss";
</style>
